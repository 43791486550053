.ant-btn-primary {
  background-color: #00796b;
  border-radius: 5px;
}
.ant-btn-lg {
  height: 45px;
  min-width: 120px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  background-color: #00828e;
}
.auth-steps {
  display: flex;
  flex-direction: row;
}
