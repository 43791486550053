.barrierName {
  background-image: linear-gradient(
    -225deg,
    #00b53c 0%,
    #01baef 50%,
    #00b53c 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1s linear infinite;
  animation-delay: 0s;
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
